<template>
  <div class="container">
    <div class="title"><span @click="previousPage()">&lt;</span>激活校园ID</div>
    <div class="content">
      <div class="input1">
        <img src="../../static/mima.png" class="img">
        <input
          v-model="newpwd1"
          type="password"
          class="uni-input"
          placeholder="输入新密码"
          placeholder-style="color:#BFBDBC"
          @blur="mimayz()"
        >
      </div>
      <span
        v-if="show"
        id="mima_span"
        style="color: red"
        v-text="mima_span_value"
      />
      <!-- 密码强度组件 -->
      <div v-show="strongshow" class="mimastrong">
        <div class="strongcolor">
          <span class="item1" />
          <span v-show="item2show" class="item2" />
          <span v-show="item3show" class="item3" />
        </div>
        <span class="strongmsg" v-text="mimastrong" />
      </div>
      <div class="input1">
        <img src="../../static/mima.png" class="img">
        <input
          v-model="newpwd2"
          type="password"
          class="uni-input"
          placeholder="再次输入新密码"
          placeholder-style="color:#BFBDBC"
          @blur="mimayz2()"
        >
      </div>

      <span
        v-if="show2"
        id="mima_span2"
        style="color: red"
        v-text="mima_span_value2"
      />

      <div class="input1 input-yz">
        <img src="../../static/phone.png" class="img-yz">
        <input
          v-model="phone"
          type="number"
          placeholder="输入绑定的手机号"
          placeholder-style="color:#BFBDBC"
          style="width: 40vw"
        >
        <span
          v-if="phone_span_show"
          class="phone_span"
          v-text="phone_span_value"
        />
        <span v-if="yz_btn_show" class="red" @click="getmsg()">获取验证码</span>
        <span v-if="yz_text_show" class="yz_text">{{ time }}</span>
      </div>
      <div class="input1">
        <img src="../../static/check.png" class="img">
        <input
          v-model="smsCode"
          type="text"
          placeholder="输入手机验证码"
          placeholder-style="color:#BFBDBC"
        >
        <span v-if="smsCode_show" class="span2" v-text="smsCode_value" />
      </div>
      <div class="input1">
        <img src="../../static/email.png" class="img">
        <input
          v-model="e_maildata"
          type="text"
          placeholder="输入绑定的邮箱号(可不填)"
          placeholder-style="color:#BFBDBC"
          @blur="emailyz()"
        >
      </div>
      <span
        v-if="e_maildatashow"
        id="email_span"
        style="color: red"
      >邮箱格式错误</span>
      <div class="input-end">
        <span>注意：每个账号仅限激活一次！请妥善保管好账号密码。</span>
      </div>
    </div>
    <div class="footer">
      <span class="end" @click="jihuo()">确&nbsp;认</span>
    </div>
  </div>
</template>

<script>
/*
 * 正则校验
 */
import {
  isNewPwdBlank,
  isPhone,
  isNewPwdChinese,
  isNewPwdStrength,
  isNewPwdStrengthStronger
} from '../../util/validate';
import { updateInfo, sendSmsForNew } from '../../api';
export default {
  props: ['code', 'workNo', 'name', 'ids', 'cType'],
  data() {
    return {
      newpwd1: '',
      newpwd2: '',
      show: false,
      show2: false,
      phone: '',
      smsCode: '',
      url: '../../static/beye.png',
      yz_btn_show: true,
      yz_text_show: false,
      time: 60,
      e_maildata: '',
      e_maildatashow: false,
      smsCode_show: false,
      smsCode_value: '',
      mima_span_value: '',
      mima_span_value2: '',
      phone_span_show: false,
      phone_span_value: '',
      mimastrong: '较弱',
      strongshow: false,
      item2show: 0,
      item3show: 0
    };
  },
  created() {
    let msg = this.$createElement(
      'span',
      { style: 'white-space:pre-wrap;overflow:hidden;' },
      '温馨提示\n\n新密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符'
    );
    this.$message({
      message: msg
    });
  },
  updated() {
    this.phone = this.phone > 11 ? this.phone.slice(0, 11) : this.phone;
    this.strongshow = !!this.newpwd1;
    if (isNewPwdStrengthStronger(this.newpwd1)) {
      this.mimastrong = '较强';
      this.item2show = 1;
      this.item3show = 1;
    } else {
      if (isNewPwdStrength(this.newpwd1)) {
        this.mimastrong = '一般';
        this.item2show = 1;
      } else {
        this.item2show = 0;
        this.item3show = 0;
      }
    }
  },
  methods: {
    mimayz2() {
      if (!this.newpwd2) {
        this.mima_span_value2 = '请输入内容';
        this.show2 = true;
      } else {
        this.show2 = false;
        this.mima_span_value2 = '';
        if (this.newpwd1 != this.newpwd2) {
          this.mima_span_value2 = '两次密码输入不一致';
          this.show2 = true;
        } else {
          this.show2 = false;
          if (
            this.newpwd2.length < 8 ||
            this.newpwd2.length > 16 ||
            !isNewPwdStrength(this.newpwd2)
          ) {
            this.mima_span_value2 = '8-16位(含字母、数字和特殊字符)';
            this.show2 = true;
          } else {
            this.show2 = false;
            if (!isNewPwdBlank(this.newpwd2)) {
              this.mima_span_value2 = '格式错误，存在空格';
              this.show2 = true;
            } else {
              this.show2 = false;
              if (!isNewPwdChinese(this.newpwd2)) {
                this.mima_span_value2 = '格式错误，存在中文';
                this.show2 = true;
              } else {
                this.show2 = false;
              }
            }
          }
        }
      }
    },
    mimayz() {
      if (this.newpwd1) {
        if (
          this.newpwd1.length < 8 ||
          this.newpwd1.length > 16 ||
          !isNewPwdStrength(this.newpwd1)
        ) {
          this.mima_span_value = '8-16位(含字母、数字和特殊字符)';
          this.show = true;
        } else {
          this.show = false;
          if (!isNewPwdBlank(this.newpwd1)) {
            this.mima_span_value = '格式错误，存在空格';
            this.show = true;
          } else {
            this.show = false;
            if (!isNewPwdChinese(this.newpwd1)) {
              this.mima_span_value = '格式错误，存在中文';
              this.show = true;
            } else {
              this.show = false;
            }
          }
        }
      } else {
        this.mima_span_value = '请输入内容';
        this.show = true;
      }
    },
    previousPage() {
      history.go(-1);
    },
    getmsg() {
      if (!isNewPwdStrength(this.newpwd1)) {
        let msg = this.$createElement(
          'span',
          { style: 'white-space:pre-wrap;overflow:hidden;' },
          '不符合密码规则，请确认后输入！\n密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符'
        );
        this.$message({
          message: msg
        });
        this.mima_span_value = '';
        this.show = true;
      } else {
        this.show = false;
      }
      if (!isNewPwdStrength(this.newpwd2)) {
        let str =
          this.mima_span_value2 == '两次密码输入不一致'
            ? '\n\n两次密码输入不一致'
            : '';
        let msg = this.$createElement(
          'span',
          { style: 'white-space:pre-wrap;overflow:hidden;' },
          '不符合密码规则，请确认后输入！\n密码规则：由字母大小写，数字，特殊字符三种组成的8~16位长度字符' +
            str
        );
        this.$message({
          message: msg
        });
        this.mima_span_value = '';
        this.show = true;
      } else {
        this.show = false;
      }
      if (!this.show && !this.show2) {
        if (this.phone == '') {
          this.phone_span_value = '请先输入手机号';
          this.phone_span_show = true;
        } else if (isPhone(this.phone)) {
        // 获取验证码接口数据请求
          this.phone_span_show = false;
          sendSmsForNew({
            mobileNumber: this.phone,
            activeCode: this.code,
            workNo: this.workNo
          }).then((res) => {
            if (res.data.code == 200) {
              this.yz_btn_show = false;
              this.yz_text_show = true;
              setInterval(() => {
                this.time--;
                if (this.time < 0) {
                  this.yz_btn_show = true;
                  this.yz_text_show = false;
                  this.time = 60;
                  clearInterval();
                }
              }, 1000);
              this.phone_span_value = res.data.msg;
              this.phone_span_show = true;
              setTimeout(() => {
                this.phone_span_show = false;
              }, 5000);
            } else {
              this.phone_span_value = res.data.msg;
              this.phone_span_show = true;
              setTimeout(() => {
                this.phone_span_value = '';
              }, 5000);
            }
          });
        } else {
          this.phone_span_value = '手机号格式错误';
          this.phone_span_show = true;
          setTimeout(() => {
            this.phone_span_value = '';
          }, 3000);
        }
      }
    },
    emailyz() {
      // 邮箱验证
      let exg =
        /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/g;
      if (
        this.e_maildata == '' ||
        (this.e_maildata && exg.test(this.e_maildata))
      ) { this.e_maildatashow = false; } else this.e_maildatashow = true;
    },
    jihuo() {
      if (this.phone == '') {
        this.phone_span_value = '请先输入手机号';
        this.phone_span_show = true;
        setTimeout(() => {
          this.phone_span_value = '';
        }, 5000);
      }
      this.mimayz();
      this.mimayz2();
      console.log(this.phone_span_show);
      if (!this.e_maildatashow && !this.phone_span_show) {
        updateInfo({
          code: this.code,
          mobileNumber: this.phone,
          name: this.name,
          ids: this.ids,
          pwd: this.newpwd1,
          smsCode: this.smsCode,
          workNo: this.workNo,
          email: this.e_maildata,
          cType: this.cType
        })
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) this.$router.push('/Active_sucess');
            else {
              this.smsCode_value = res.data.msg;
              this.smsCode_show = true;
            }
          })
          .catch((err) => {
            this.$message.error(err);
            setTimeout(() => {
              this.smsCode_value = '';
            }, 5000);
            this.$router.push('/Active_fail');
          });
      } else {
        this.smsCode_value = '验证码错误';
        this.smsCode_show = true;
        setTimeout(() => {
          this.smsCode_value = '';
        }, 5000);
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
@media screen and(-ms-high-contrast:active), (-ms-high-contrast: none) {
  /*兼容IE11*/
  .red {
    position: absolute;
    top: 58.5vw;
    width: 27vw;
    height: 10.68vw;
    line-height: 11.57vw;
    right: 10vw;
    color: #fff;
    background-color: #da251c;
    font-size: 3.2vw;
    border-radius: 10pt;
    text-align: center;
  }
}
input {
  outline: none;
}
.container {
  width: 100vw;
  height: 178vw;
  background-color: #f5f5f9;
  position: static;
}

.content {
  padding: 10.68vw 10vw 10.53vw 10vw;
  margin-top: 13.47vw;
}

.title {
  width: 100%;
  height: 12.5vw;
  line-height: 12.5vw;
  font-size: 4.8vw;
  color: #fff;
  text-align: center;
  background-color: #da251c;
  position: absolute;
  top: 0;
}
.title span {
  position: absolute;
  left: 2vw;
  font-size: 8vw;
}
.img {
  width: 5.3vw;
  height: 5.3vw;
  margin: 0 3vw 0 3.2vw;
}

.img-yz {
  width: 8vw;
  height: 5.3vw;
  margin: 0 3vw 0 3.2vw;
}

.input1 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 10.68vw;
  width: 80vw;
  color: #969696;
  border: 1px solid #bfbdbc;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 5.874vw;
}

/* 输入提示消息 */
.input1 .span2 {
  color: red;
  position: absolute;
  width: 60vw;
  top: 87vw;
  left: 15vw;
  font-size: 3.5vw;
}

.mimastrong {
  position: relative;
  left: 7vw;
  width: 66vw;
  height: 8vw;
  line-height: 8vw;
  margin: 3vw 0;
  display: flex;
}
.strongcolor {
  margin-top: 2vw;
  width: 66vw;
  height: 4vw;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 1vw;
}

.strongcolor .item1 {
  background-color: #fbc4c6;
}
.strongcolor .item2 {
  background-color: #f89c95;
}
.strongcolor .item3 {
  background-color: #f56c6c;
}
.strongmsg {
  margin-left: 2vw;
  width: 18vw;
  text-align: center;
  color: #da251c;
  font-size: 3.8vw;
}

.phone_span {
  color: red;
  position: absolute;
  font-size: 3.5vw;
  left: 13.6vw;
  top: 70vw;
}

#mima_span {
  position: absolute;
  font-size: 3.2vw;
  top: 35.51vw;
  left: 13.6vw;
}

#mima_span2 {
  position: relative;;
  font-size: 3.2vw;
  left: 3vw;
  top: -5vw;
}

#email_span {
  position: absolute;
  font-size: 3.2vw;
  top: 104.13vw;
  left: 13.6vw;
}

.input-yz {
  width: 50vw;
  overflow: hidden;
}

.input-end {
  margin-bottom: 0;
}

.input-end span {
  color: #da251c;
  font-size: 3.2vw;
}

.yz_text {
  position: absolute;
  right: 20vw;
}

.red {
  position: absolute;
  width: 27vw;
  height: 10.68vw;
  line-height: 11.57vw;
  right: 10vw;
  color: #fff;
  background-color: #da251c;
  font-size: 3.2vw;
  border-radius: 10pt;
  text-align: center;
}

input {
  font-size: 3.2vw;
  width: 60vw;
  border: none;
}

.footer {
  position: relative;
  bottom: 0vw;
  width: 80%;
  height: 73.25vw;
  background-image: url("../../static/wangbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 10vw;
}

.end {
  display: block;
  width: 63.3vw;
  height: 10.68vw;
  background-image: url("../../static/denglu.png");
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 10.68vw;
  margin-left: 7.7vw;
  cursor: pointer;
}
</style>
